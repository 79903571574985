import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SubirService {
  private URL = 'https://www.ueingenieria.com/casas_mv/webapp/subir'
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) { }

lastVer(softType:String){
  return this.http.get(this.URL+'/get_last_ver.php?soft_type='+softType)
}

firmware(fmForm: FormData, pass){
  fmForm.append('password',pass);
  return this.http.post(this.URL+'/firmware.php',fmForm)
}

app(fmForm: FormData, pass){
  fmForm.append('password',pass);
  return this.http.post(this.URL+'/app.php',fmForm)
}

cfg(fmForm: FormData, pass){
  fmForm.append('password',pass);
  return this.http.post(this.URL+'/CFG.php',fmForm)
}

man(fmForm: FormData, pass){
  fmForm.append('password',pass);
  return this.http.post(this.URL+'/man.php',fmForm)
}

}
