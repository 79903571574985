import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../services/auth.service";
@Injectable({
  providedIn: 'root'
})
export class EquiposService {

  private URL = 'https://www.ueingenieria.com/casas_mv/webapp/equipos'

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  getItems(){
    return this.http.get<any>(this.URL+'/get_equipos.php')
  }

  saveItem(item,pass){
    const data = new URLSearchParams("item="+item+"&password="+pass)
    return fetch(this.URL + '/guardar.php', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${this.authService.getToken()}`
    },
    body: data
    })
  }
  setUser(item,id_user,pass){
    const data = new URLSearchParams("item="+item+"&propietario="+id_user+"&password="+pass)
    return fetch(this.URL + '/set_cliente.php', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${this.authService.getToken()}`
    },
    body: data
    })
  }
  saveComment(item){
    return this.http.post<any>(this.URL+'/guardar_comentario.php',item)
  }
}
