import { DecimalPipe } from '@angular/common';
import { Component, OnInit, PipeTransform, ɵConsole } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbPaginationNumber } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators'
import Swal from 'sweetalert2';
import { ClientesService } from './clientes.service';

export interface Client{
  id: number;
  fname: string;
  email: string;
  phone: string;
  address: string;
  tall_name: string;
}

var CLIENTES: Client[] = []

function search(text: string): Client[]{
  return CLIENTES.filter(cliente => {
    const term = text.toLowerCase();
    return cliente.fname.toLowerCase().includes(term)
      ||cliente.email.toLowerCase().includes(term)
      ||cliente.phone.toLowerCase().includes(term)
      ||cliente.address.toLowerCase().includes(term)
      ||cliente.tall_name.toLowerCase().includes(term);
  })
}

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css'],
  providers: [DecimalPipe]
})
export class ClientesComponent implements OnInit {
  public clientes$: Observable<Client[]>;
  filter = new FormControl('');
  public loading :boolean = true;

  constructor(
    private clientesService:ClientesService,
    // pipe: DecimalPipe
  ) {
    
   }
  
  onSearch(){
    return this.filter.valueChanges.pipe(
      startWith(''),
      map(text => search(text))
    )
  }

  ngOnInit(): void {
    this.clientesService.getClientes().subscribe(
      res => {
        CLIENTES = res;
      },
      err=> {
        console.log(err);
      },
      () => {
        this.clientes$ = this.onSearch()
        this.loading = false
      }
    )
  }

  onAdd(){
    var nuevo_cliente :Client;
    Swal.fire({
      title:'Añadir cliente',
      showCancelButton: true,
      confirmButtonText: 'Añadir',
      html:'<form id="add-form" style="text-align: start;">'+
            '<div class="form-group">'+
              '<label for="nombre" style="text-align:start !important;">Nombre</label>'+
              '<input type="text" style="border-radius: 5px !important; border: 1px solid lightgray !important;" class="form-control" id="nombre" placeholder="Jorge Casas" required>'+
            '</div>'+
            '<div class="row">'+
              '<div class="col">'+
                '<label for="email">Email</label>'+
                '<input id="email" style="border-radius: 5px !important; border: 1px solid lightgray !important;" type="email" class="form-control" placeholder="email@ejemplo.com" required>'+
              '</div>'+
              '<div class="col">'+
                '<label for="telefono">Telefono</label>'+
                '<input id="telefono" style="border-radius: 5px !important; border: 1px solid lightgray !important;" type="text" class="form-control" placeholder="(555)123-456" pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.\/0-9]*$">'+
              '</div>'+
            '</div>'+
            '<div class="form-group">'+
              '<label for="direccion">Dirección</label>'+
              '<input type="text" style="border-radius: 5px !important; border: 1px solid lightgray !important;" class="form-control" id="direccion" placeholder="Rivadavia 177">'+
            '</div>'+
            '<div class="form-group">'+
            '<label for="taller">Nombre de Taller</label>'+
            '<input type="text" style="border-radius: 5px !important; border: 1px solid lightgray !important;" class="form-control" id="taller" placeholder="Taller Casas">'+
          '</div>'+
        '</form>',
      preConfirm: () =>
            {
              let form = document.getElementById("add-form") as HTMLFormElement
              form.setAttribute("class","was-validated")
              let nombre = document.getElementById("nombre") as HTMLInputElement
                if (nombre.value == ""){
                  Swal.showValidationMessage("Debe ingresar un nombre");
                  return
                }
              let email = document.getElementById("email") as HTMLInputElement
              if (!(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email.value))){
                Swal.showValidationMessage("Debe ingresar un mail válido");
                return
              }
              let telefono = document.getElementById("telefono") as HTMLInputElement
              if (!(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.\/0-9]*$/.test(telefono.value))&&telefono.value!=""){
                Swal.showValidationMessage("Debe ingresar un teléfono válido");
                return
              }
              let direccion = document.getElementById("direccion") as HTMLInputElement
              let taller = document.getElementById("taller") as HTMLInputElement
              let idNuevo :number = (CLIENTES[(CLIENTES.length-1)].id)
              idNuevo++;
              nuevo_cliente = {
                id: idNuevo,
                fname: nombre.value,
                email: email.value,
                phone: telefono.value,
                address: direccion.value,
                tall_name: taller.value
              }
            }
    }).then((result) => {
      if (result.isConfirmed){
        CLIENTES.push(nuevo_cliente);
        this.clientes$ = this.onSearch()
        this.clientesService.addClientes(nuevo_cliente).subscribe(
          res => {
            Swal.fire({
              icon:'success',
              title: `${res.msg}`,
              showConfirmButton: false,
              timer:1500,
              toast:true
            })
          },
          err => {
            Swal.fire({
              icon:'error',
              title: `${err.error}`,
              showConfirmButton: false,
              timer:1500,
              toast:true
            })
          }
        )
      }
    })
    

  }

  onEdit(itemId){
    itemId--;
    Swal.fire({
      title:'Editar cliente',
      showCancelButton: true,
      confirmButtonText: 'Editar',
      html:'<form id="edit-form" style="text-align: start;">'+
            '<div class="form-group">'+
              '<label for="nombre" style="text-align:start !important;">Nombre</label>'+
              '<input type="text" style="border-radius: 5px !important; border: 1px solid lightgray !important;" class="form-control" id="nombre" placeholder="Jorge Casas" required>'+
            '</div>'+
            '<div class="row">'+
              '<div class="col">'+
                '<label for="email">Email</label>'+
                '<input id="email" style="border-radius: 5px !important; border: 1px solid lightgray !important;" type="email" class="form-control" placeholder="email@ejemplo.com" required>'+
              '</div>'+
              '<div class="col">'+
                '<label for="telefono">Telefono</label>'+
                '<input id="telefono" style="border-radius: 5px !important; border: 1px solid lightgray !important;" type="text" class="form-control" placeholder="(555)123-456" pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.\/0-9]*$">'+
              '</div>'+
            '</div>'+
            '<div class="form-group">'+
              '<label for="direccion">Dirección</label>'+
              '<input type="text" style="border-radius: 5px !important; border: 1px solid lightgray !important;" class="form-control" id="direccion" placeholder="Rivadavia 177">'+
            '</div>'+
            '<div class="form-group">'+
            '<label for="taller">Nombre de Taller</label>'+
            '<input type="text" style="border-radius: 5px !important; border: 1px solid lightgray !important;" class="form-control" id="taller" placeholder="Taller Casas">'+
          '</div>'+
        '</form>',
      willOpen: () => {
        let nombre = document.getElementById("nombre") as HTMLInputElement
        let email = document.getElementById("email") as HTMLInputElement
        let telefono = document.getElementById("telefono") as HTMLInputElement
        let direccion = document.getElementById("direccion") as HTMLInputElement
        let taller = document.getElementById("taller") as HTMLInputElement
        nombre.value = CLIENTES[itemId].fname
        email.value = CLIENTES[itemId].email
        telefono.value = CLIENTES[itemId].phone
        direccion.value = CLIENTES[itemId].address
        taller.value = CLIENTES[itemId].tall_name
      },
      preConfirm: () =>
            {
              let form = document.getElementById("edit-form") as HTMLFormElement
              form.setAttribute("class","was-validated")
              let nombre = document.getElementById("nombre") as HTMLInputElement
                if (nombre.value == ""){
                  Swal.showValidationMessage("Debe ingresar un nombre");
                  return
                }
              let email = document.getElementById("email") as HTMLInputElement
              if (!(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email.value))){
                Swal.showValidationMessage("Debe ingresar un mail válido");
                return
              }
              let telefono = document.getElementById("telefono") as HTMLInputElement
              if (!(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.\/0-9]*$/.test(telefono.value))&&telefono.value!=""){
                Swal.showValidationMessage("Debe ingresar un teléfono válido");
                return
              }
              CLIENTES[itemId].address = (document.getElementById("direccion") as HTMLInputElement).value
              CLIENTES[itemId].tall_name = (document.getElementById("taller") as HTMLInputElement).value
              CLIENTES[itemId].fname = nombre.value
              CLIENTES[itemId].email = email.value
              CLIENTES[itemId].phone = telefono.value

            }
    }).then((result) => {
      if (result.isConfirmed){
        this.clientes$ = this.onSearch()
        this.clientesService.editCliente(CLIENTES[itemId]).subscribe(
          res => {
            Swal.fire({
              icon:'success',
              title: `${res.msg}`,
              showConfirmButton: false,
              timer:1500,
              toast:true
            })
          },
          err => {
            Swal.fire({
              icon:'error',
              title: `${err.error}`,
              showConfirmButton: false,
              timer:1500,
              toast:true
            })
          }
        )
      }
    })
  }
}
