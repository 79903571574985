<h1>Clientes MVA</h1>
<form>
    <div class="form-group form-inline">
      Buscar: <input class="form-control ml-2" type="text" [formControl]="filter"/>
      <a class="btn btn-primary text-white" (click)="onAdd()"><i class="fas fa-user-plus"></i></a>  
    </div>
  </form>
<table class="table table-striped table-dark">
  <thead class="thead-light">
  <tr>
    <th scope="col">#</th>
    <th scope="col">Nombre</th>
    <th scope="col">Email</th>
    <th scope="col">Telefono</th>
    <th scope="col">Direccion</th>
    <th scope="col">Taller</th>
    <th></th>
</tr>
  </thead>
  <tbody>
  <tr *ngFor="let cliente of clientes$ | async; index as i">
    <th scope="row">{{ i + 1 }}</th>
    <td><ngb-highlight [result]="cliente.fname" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="cliente.email" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="cliente.phone" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="cliente.address" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="cliente.tall_name" [term]="filter.value"></ngb-highlight></td>
    <td><a class="btn btn-secondary text-white" (click)="onEdit(cliente.id)"><i class="fas fa-user-edit"></i></a></td>
  </tr>
  </tbody>
</table>
<div *ngIf="loading" class="row justify-content-center">
  <i class="fas fa-circle-notch spinner"></i>
</div>