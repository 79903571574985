<ng-container *ngIf="this.islogged()">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand mb-0 h1">
      <img src="/assets/img/MVA-Logo.png" alt="Casas MVA" width="30" height="30" class="d-inline-block align-top" loading="lazy">
      Casas MVA</span>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="/clientes" routerLinkActive>Clientes</a>
        </li>
        <li>
          <a class="nav-link" routerLink="/equipos" routerLinkActive>Equipos<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Subir
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" routerLink="/subir/firmware" routerLinkActive>Nuevo Firmware</a>
            <a class="dropdown-item" routerLink="/subir/app" routerLinkActive>Nueva App</a>
            <a class="dropdown-item" routerLink="/subir/cfg" routerLinkActive>Nuevo Cfg</a>
            <a class="dropdown-item" routerLink="/subir/man" routerLinkActive>Nuevo Manual</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item">Historial de versiones</a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav justify-content-end">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="dropUser" role="button" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{userLoged()}}
          </a>
          <div class="dropdown-menu dropdown-menu-right navbarDropdown">
            <a (click)="this.logout()" class="dropdown-item">
              Logout
            </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</ng-container>
<div class="container p-3">
  <router-outlet></router-outlet>
</div>