import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  private URL = 'https://www.ueingenieria.com/casas_mv/webapp'
  
  constructor(
    private http: HttpClient,
    private router: Router
    ) { }

  login(user){
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(this.URL+'/login.php',user)
  }
  
  loggedIn(): Boolean {
    return !!sessionStorage.getItem('token')&&(parseInt(sessionStorage.getItem('tokenExpiration'))*1000)> Date.now();
  }

  getToken(){
    return sessionStorage.getItem('token');
  }

  logout(){
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('adminId');
    sessionStorage.removeItem('adminUser');
    sessionStorage.removeItem('tokenExpiration');
    this.router.navigate(['/login']);
  }
}
