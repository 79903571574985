import { Component, OnInit, ViewChild } from '@angular/core';
import { EquiposService } from "./equipos.service";
import Swal from "sweetalert2";

  interface Clientes {
    id: number,
    fname: string,
    email: string
  }

@Component({
  selector: 'app-equipos',
  templateUrl: './equipos.component.html',
  styleUrls: ['./equipos.component.css']
})
export class EquiposComponent implements OnInit {

  public firm = []
  public app = []
  public cfg = []
  public man = []
  public admin = []
  public equipos = []
  public clientes: Clientes[] = []

  model: any[] = [];
  public isCollapsed: boolean[] = [];

  public loading: boolean = true

  constructor(
    private equiposService:EquiposService,
  ) {}

  ngOnInit(): void {
    this.equiposService.getItems().subscribe(
      res =>{
        this.firm = res.firm_table;
        this.cfg = res.cfg_table;
        this.app = res.app_table;
        this.man = res.man_table;
        this.admin = res.admin_table;
        this.equipos = res.equipos;
        this.clientes = res.clientes_table;
        this.isCollapsed.length=this.equipos.length;
        this.isCollapsed.fill(true);
      },
      err => {
        console.log(err);
      },
      () => {
        this.loading = false
      }
    )
    
  }
  encontrarIndexCliente(idCliente){
    return this.clientes.indexOf(this.clientes.find(cix => cix.id == idCliente));
  }
  enableSaveButton(i,changeUpdStatus?){
    changeUpdStatus = changeUpdStatus || false;
    let but = document.getElementById('saveButton-'+i) as HTMLButtonElement
    but.disabled = false;
    if(changeUpdStatus){
      this.equipos[i].estado_ultima_actual = 0;
    }
  }
  adminNombre(id){
    const findById = (elemento)=> elemento.id == id;
    return this.admin[this.admin.findIndex(findById)].nombre;
  }
  saveItem(index){ 
    
    this.isCollapsed.fill(true);

    Swal.fire({
      title:`Guardar cambios en ${this.equipos[index].numero_serie}?`,
      input: 'password',
      inputPlaceholder: 'Ingresa contraseña para confirmar',
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: (contraseña) => {
        if (contraseña==""){
          Swal.showValidationMessage('Debe ingresar una contraseña')
          return
        }
        // if (this.equipos[index].propietario == null){
        //   this.equipos[index].propietario = '';
        // }
        return this.equiposService.saveItem(JSON.stringify(this.equipos[index]),contraseña)
        .then(async response => {
          if(!response.ok){
            throw new Error (await response.text())
          }
          this.equipos[index].fecha_ultima_actualizacion = (new Date).toISOString();
          this.equipos[index].id_admin_ultima_actualiz = sessionStorage.getItem('adminId');
          return response.text()
        })
        .catch(error => {
          Swal.showValidationMessage(error)
        })
      }
    }).then ((result) => {
      if(!(result.dismiss===Swal.DismissReason.cancel)){       
        Swal.fire({
          title: String(result['value']),
          icon: 'success',
          toast: true,
          showConfirmButton: false,
          timer: 1000,
        })
        let but = document.getElementById('saveButton-'+index) as HTMLButtonElement
        but.disabled = true;
      }
    })
  }
  viewComment(index){
    Swal.fire({
      title: `Comentario en ${this.equipos[index].numero_serie}`,
      input: "textarea",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Guardar",
      inputValue: this.equipos[index].comentarios
    }).then((result)=>{
      if (result.isConfirmed){
        this.equipos[index].comentarios = result.value;
        this.equiposService.saveComment(this.equipos[index]).subscribe(
          res => {
            Swal.fire({
              icon:'success',
              title: `${res.msg}`,
              showConfirmButton: false,
              timer:1500,
              toast:true
            })
          },
          err => {
            Swal.fire({
              icon:'error',
              title: `${err.error}`,
              showConfirmButton: false,
              timer:1500,
              toast:true
            })
          }
        )
      }
    })
  }
}