import { Component, OnInit } from '@angular/core';
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  admin = {
    user: '',
    pass: ''
  };
  
  loading: boolean = false;

  constructor(
    private authService: AuthService,
    private router:Router,
    private appComponent: AppComponent
  ) {}

  ngOnInit(): void {
  }
  
  logIn(){
    if(!(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test((document.getElementById('user') as HTMLInputElement).value)) || (document.getElementById('pass') as HTMLInputElement).value=="" ){
      (document.getElementById('logForm') as HTMLInputElement).setAttribute('class','was-validated');
      return
    }
    this.loading = true
    this.authService.login(this.admin)
    .subscribe(
      res => {
        Swal.fire({
          title: 'Sesion iniciada',
          icon: 'success',
          toast: true,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false
        })
        sessionStorage.setItem('token', res.token);
        sessionStorage.setItem('adminUser', res.nombre);
        sessionStorage.setItem('adminId', res.id);
        sessionStorage.setItem('tokenExpiration',res.exp);
        // this.appComponent.userLoged = sessionStorage.getItem('adminUser');
        this.router.navigate(['/equipos']);
      },
      err => {
        Swal.fire({
          title: 'Error',
          text: err.error,
          icon: 'error',
          toast: true,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false
        })
        this.loading=false
      }
    )
  }

}
