<h1>Nuevo CFG MVA:</h1>

<form [formGroup]="fmForm" (ngSubmit)="submit()" id="fmForm">
      <div class="form-group">
            <div class="mb-3">
                  <label for="versionInput">Versión:</label>
                  <input formControlName="version" type="text" class="form-control" id="versionInput" placeholder={{placeholderVersion}} pattern="^[0-9]+(\.[0-9]+)*$"  required>
                  <div class="invalid-feedback">Ingrese versión o compruebe el formato ingresado (ej: 1.2.3)</div>
            </div>
            <div class="mb-3">
                  <label for="notasText">Notas de versión:</label>
                  <textarea formControlName="notas" class="form-control" id="notasText" placeholder="Historial de cambios" required></textarea>
                  <div class="invalid-feedback">
                        Debe agregar notas acerca de la version
                  </div>
                </div>
            <div class="custom-file mb-3">
                  <input type="file" class="custom-file-input" id="fmFile" (change)="uploadFile($event)" accept=".cfg" required>
                  <label id="file-label" class="custom-file-label" for="fmFile">Seleccionar archivo...</label>
                  <div class="invalid-feedback">Debe seleccionar un archivo</div>
            </div>
            <div>
                  <input class="btn btn-primary" type="submit" value="Subir">
                  <input class="btn btn-secondary" type="reset" value="Limpiar">
            </div>
      </div>