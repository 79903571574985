<h1>Equipos MVA</h1>
<div class="row justi">
    <div class="table-responsive-md">
        <table class="table table-striped table-dark">
                <thead class="thead-light">
                    <tr>
                        <th>Nro Serie</th>
                        <th>Propietario</th>
                        <th></th>
                        <th>Firmware</th>
                        <th>CFG</th>
                        <th>App</th>
                        <th>Manuales</th>
                        <th>Ultima Actualización</th>
                        <th>Realizada por</th>
                        <th>Comentarios</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of equipos; let i = index">
                        <td>{{item.numero_serie}}</td>

                        <td *ngIf="item.propietario!=null;else elseBlock1">
                            <div>
                                {{clientes[encontrarIndexCliente(item.propietario)].fname}}
                                <br>
                                <span class="badge badge-secondary">{{clientes[encontrarIndexCliente(item.propietario)].email}}</span>
                            </div>

                            <div id="collapseExample" [ngbCollapse]="isCollapsed[i]">
                                <select [(ngModel)]="equipos[i].propietario" (change)="enableSaveButton(i)">
                                    <option class="form-control" *ngFor="let cliente of clientes" [ngValue]="cliente.id">{{cliente.fname}}</option>
                                </select>
                            </div>
                        </td>
                        <td>
                            <a class="btn btn-secondary text-white" (click)="isCollapsed[i] =!isCollapsed[i]" [attr.aria-expanded]="!isCollapsed[i]" aria-controls="collapseExample">
                                <i class="fas fa-user-edit"></i>
                            </a>
                        </td>
                        <ng-template #elseBlock1>
                            <td>
                                <span class="badge badge-warning">No asignado</span>
                                <div id="collapseExample" [ngbCollapse]="isCollapsed[i]">
                                    <select [(ngModel)]="equipos[i].propietario"(change)="enableSaveButton(i,false)">
                                        <option class="form-control" *ngFor="let cliente of clientes" [ngValue]="cliente.id">{{cliente.fname}}</option>
                                    </select>
                                </div>
                            </td>
                        </ng-template>
                        <!-- <td>{{item.id_firmware_version}}</td> -->
                        <td>
                            <select [(ngModel)]="equipos[i].id_firmware_version" (change)="enableSaveButton(i,true)">
                                <option *ngFor="let firms of firm" [ngValue]="firms.id">{{firms.version}}</option>
                            </select>
                        </td>
                        <td>
                            <select [(ngModel)]="equipos[i].id_cfg_version" (change)="enableSaveButton(i,true)">
                                <option *ngFor="let cfgs of cfg" [ngValue]="cfgs.id">{{cfgs.version}}</option>
                            </select>
                        </td>
                        <!-- <td>{{item.id_app_version}}</td> -->
                        <td>
                            <select [(ngModel)]="equipos[i].id_app_version" (change)="enableSaveButton(i,true)">
                                <option *ngFor="let apps of app" [ngValue]="apps.id">{{apps.version}}</option>
                            </select>
                        </td>
                        <!-- <td>{{item.id_man_version}}</td> -->
                        <td>
                            <select [(ngModel)]="equipos[i].id_man_version" (change)="enableSaveButton(i,true)">
                                <option *ngFor="let mans of man" [ngValue]="mans.id">{{mans.version}}</option>
                            </select>
                        </td>
                        <!-- <td>{{item.fecha_ultima_actualizacion}}</td> -->
                        <td *ngIf="item.estado_ultima_actual==0;else elseBlock3">
                            {{item.fecha_ultima_actualizacion}} <i class="fas fa-clock"></i>
                        </td>
                        <ng-template #elseBlock3>
                            <td>{{item.fecha_ultima_actualizacion}} <i style="color: greenyellow;" class="fas fa-check"></i></td>
                        </ng-template>
                        <td *ngIf="item.id_admin_ultima_actualiz!=null;else elseBlock2">{{adminNombre(item.id_admin_ultima_actualiz)}}</td>
                        <ng-template #elseBlock2>
                            <td><span class="badge badge-info">No actualizado</span></td>
                        </ng-template>
                        <td (click)="viewComment(i)" class="backhover">
                            <span class="truncate">{{item.comentarios}}</span>
                        </td>
                        <td>
                            <button id="saveButton-{{i}}" class="btn btn-danger text-white" (click)="saveItem(i)" disabled>
                                <i class="fas fa-save"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
        </table>
        <div *ngIf="loading" class="row justify-content-center">
            <i class="fas fa-circle-notch spinner"></i>
        </div>
    </div>
</div>