import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EquiposComponent } from './equipos/equipos.component';
import { AuthGuard } from './auth.guard';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { NuevoFirmwareComponent } from './subir/nuevo-firmware/nuevo-firmware.component';
import { ClientesComponent } from './clientes/clientes.component';
import { NuevaAppComponent } from './subir/nueva-app/nueva-app.component';
import { NuevoCfgComponent } from './subir/nuevo-cfg/nuevo-cfg.component';
import { NuevoManComponent } from './subir/nuevo-man/nuevo-man.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EquiposComponent,
    NuevoFirmwareComponent,
    ClientesComponent,
    NuevaAppComponent,
    NuevoCfgComponent,
    NuevoManComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi:true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
