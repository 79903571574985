import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SubirService } from "../subir.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-nuevo-man',
  templateUrl: './nuevo-man.component.html',
  styleUrls: ['./nuevo-man.component.css']
})
export class NuevoManComponent implements OnInit {

  fmForm = new FormGroup({
    version: new FormControl('', [Validators.required,Validators.pattern('^[0-9]+(\.[0-9]+)*$')]),
    notas: new FormControl('', Validators.required),
    archivo: new FormControl('', [Validators.required]),
  });

  placeholderVersion = '';


  constructor(
    private subirService: SubirService
  ) { }

  ngOnInit(): void {
    this.subirService.lastVer('man').subscribe(
      res => {
        this.placeholderVersion = 'Última version: '+res['version'];
      }
    )
  }

  uploadFile(event){
    let label:HTMLElement = document.getElementById('file-label');
    label.innerText = event.target.files[0].name + " (" + Math.round(event.target.files[0].size/1024) + " KB)";
    console.log(event.target.files[0]);
    const file = (event.target as HTMLInputElement).files[0];
    this.fmForm.patchValue({
      archivo: file
    });
    this.fmForm.get('archivo').updateValueAndValidity();
  }

  submit(){
    if(!(/^[0-9]+(\.[0-9]+)*$/.test(this.fmForm.get('version').value))||this.fmForm.get('notas').value==''||this.fmForm.get('archivo').value==''){
      (document.getElementById('fmForm') as HTMLInputElement).setAttribute('class','was-validated');
      return
    }
    const formData = new FormData()
    formData.append('version',this.fmForm.get('version').value);
    formData.append('notas',this.fmForm.get('notas').value)
    formData.append('upfile',this.fmForm.get('archivo').value)
    Swal.fire({
      title:'Debe ingresar su contraseña para confirmar',
      input:'password',
      showLoaderOnConfirm: true,
      allowOutsideClick: ()=>!Swal.isLoading(),
      preConfirm: (pass)=>{
          this.subirService.man(formData,pass).subscribe(
            res => {
              console.log(res);
              return res;
            },
            err => {
              Swal.hideLoading();
              Swal.showValidationMessage(`${err.error}`);
            },
            () => {
              Swal.hideLoading();
              Swal.fire({
                icon:'success',
                title: 'Subido',
                showConfirmButton: false,
                showCloseButton: true,
                toast:true
              })
            }
          )
      },
      showConfirmButton: true,
      confirmButtonText: 'Subir'
    })
    .then((result)=>{
      if(result.isConfirmed)
      {
        Swal.fire({
        title:'Subiendo...',
        allowOutsideClick: ()=> !Swal.isLoading(),
        willOpen: ()=>{
            Swal.showLoading()
          }
        })
      }
    })
  }

}
