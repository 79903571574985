import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { EquiposComponent } from "./equipos/equipos.component";
import { AuthGuard } from './auth.guard';
import { NuevoFirmwareComponent } from './subir/nuevo-firmware/nuevo-firmware.component';
import { NuevaAppComponent } from "./subir/nueva-app/nueva-app.component";
import { NuevoCfgComponent } from "./subir/nuevo-cfg/nuevo-cfg.component";
import { NuevoManComponent } from "./subir/nuevo-man/nuevo-man.component";
import { ClientesComponent } from './clientes/clientes.component';


const routes: Routes = [
  {
    path:'',
    redirectTo: '/equipos',
    pathMatch: 'full'
  },
  {
    path:'login',
    component: LoginComponent
  },
  {
    path:'equipos',
    component: EquiposComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'subir/firmware',
    component: NuevoFirmwareComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'subir/app',
    component: NuevaAppComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'subir/cfg',
    component: NuevoCfgComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'subir/man',
    component: NuevoManComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'clientes',
    component: ClientesComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
