import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client } from "./clientes.component";

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  private URL = 'https://www.ueingenieria.com/casas_mv/webapp/clientes'

  constructor(
    private http: HttpClient
  ) { }

  getClientes(){
    return this.http.get<any>(this.URL+'/get_clientes.php')
  }

  addClientes(user:Client){
    return this.http.post<any>(this.URL+'/add_cliente.php',user);
  }

  editCliente(user:Client){
    return this.http.post<any>(this.URL+'/edit_cliente.php',user);
  }
}
