import { Component } from '@angular/core';
import { AuthService } from "./services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    private authService: AuthService
  ){}
  title = 'Casas MVA';
  // public userLoged :string
  
  userLoged(){
    return sessionStorage.getItem('adminUser')
  }

  logout(){
    this.authService.logout()
  }
  islogged(){
    return this.authService.loggedIn();
  }
}
